import * as React from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

function Hero({ miniHero }) {
  const classes = classnames(styles.hero, 'hero', 'mb-3', {
    'hero-sm': miniHero,
    [styles.miniHero]: miniHero,
    'hero-lg': !miniHero
  })

  return (
    <div className={classes}>
      <div className="hero-body text-center text-light">
        <h1>Marketplace de Alquiler de Viviendas</h1>
        <p className="mb-0"><b>version de PRUEBA [grupo3]</b></p>
      </div>
    </div>
  )
}

export default Hero
