import * as React from 'react'
import classnames from 'classnames'

import KeyFeatures from '../keyFeatures'
import Gallery from '../gallery'
import Map from '../map'

import { asCurrency } from '../../utils/number'
import styles from './styles.module.css'

function PropertyDetails({ listing }) {
  if (!listing) {
    return null
  }

  const { image, title, address, description, price, features, details } = listing
  const priceClasses = classnames(styles.price, 'text-success', 'text-right')

  return (
    <div className={styles.container}>
      <div className="columns">
        <div className="column col-9 col-xs-12">
          <h2>{title}</h2>
          <h3 className="text-dark text-small mb-1">{description}</h3>
        </div>
        <div className="column col-3 col-xs-12">
          <h5 className={priceClasses}>
            <small>Precio mensual</small>
            <br />
            USD$
            {asCurrency(price)}
          </h5>
          <div class="vertical-center">
              <button className="btn btn-primary">Alquilar</button>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column col-6 col-xs-12">
          <Gallery image={image} title={title} />
        </div>
        <div className="column col-6 col-xs-12">
          <KeyFeatures features={features} />
        </div>
      </div>
      <p className="text-bold mt-3">Detalles</p>
      {details.map(detail => (
        <p key={detail}>{detail}</p>
      ))}
      <p className="text-bold mt-3">Modelo 3D</p>
      <iframe width="853" height="480" src="https://my.matterport.com/show/?m=86LQPycQ4FA" frameborder="0" allowfullscreen allow="xr-spatial-tracking"></iframe>
      <p className="text-bold mt-3">Mapa</p>
      <Map address={address} />     
    </div>
  )
}

export default PropertyDetails
