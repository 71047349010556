import * as React from 'react'
import Hero from '../hero'

function BaseLayout({ children, miniHero = false }) {
  return (
    <>
      <main role="main" className="mb-3">
        <Hero miniHero={miniHero} />
        {children}
      </main>
      <footer className="text-center mb-5">
        Marketplace de Viviendas
        {' '}
          version de PRUEBAS [grupo3]
      </footer>
    </>
  )
}

export default BaseLayout
